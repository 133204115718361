import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/remix';
import { getSentryParams, True } from '@travelwin/core';
import { appEnv } from '@travelwin/core/src/utils/env';

const getUserCallback = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return {
    id: user.attributes.sub,
    email: user.attributes.email,
  };
};

const allowEvent = (event: Sentry.ErrorEvent) => {
  // Connection errors shouldn't be logged
  if (!navigator.onLine) {
    return false;
  }

  const errors = event.exception?.values;

  // API Compatibility errors shouldn't be logged
  const isApiCompatibilityError = errors?.find(
    (e) => e.type === 'ApiCompatibilityError',
  );

  return !isApiCompatibilityError;
};

export const SENTRY_SERVER_ERROR_PREFIX = 'Server - ';

export const prefixForServerError = (error: unknown) =>
  `${SENTRY_SERVER_ERROR_PREFIX}${error}`;

export const isServerSideError = (error: string) =>
  error.startsWith(SENTRY_SERVER_ERROR_PREFIX);

export const isNotServerSideError = (error: string) =>
  !isServerSideError(error);

export const initRemixSentry = (who: 'client' | 'server') => {
  const serverRequested = who === 'server';

  try {
    const sentryParams = getSentryParams({
      dsn: 'https://586146b24e0942aebb062b0b7adaf0a9@o4504831327666176.ingest.sentry.io/4504871062405120',
      getUserCallback,
      allowEvent: serverRequested ? True : allowEvent,
      env: serverRequested ? process.env.NODE_ENV : appEnv.getEnv(),
    });
    Sentry.init(sentryParams);
  } catch (e) {
    console.error(`Remix ${who} sentry is misconfigured ${e}`);
  }
};
