import * as Sentry from '@sentry/react';

import { appEnv } from '../utils/env';
import { getCurrentAppVersion } from './versionCheck';

/**
 * Setts up Sentry monitoring.
 *
 * We have one Sentry project per one application (slcm, retail, ecommerce) across environments,
 * hence we can hardcode the Sentry Data Source Name (dsn) key in the place where it's used.
 *
 * For the first iteration we support only errors capturing. Tracing is disabled.
 *
 * Environments are distinguished by `window.location.hostname`. If we relied on ui_settings to pass the env,
 * we would have to wait with configuring Sentry until config it's fetched,
 * hence we would miss potential errors with fetching settings.
 *
 * Sentry allows uploading source maps which would facilitate analysing stack trace, but it requires changes in the deployment process,
 * so we leave it for the next step.
 *
 * @param dsn instrumentation key from Sentry
 * @param getUserCallback function returning user details to be attached to Sentry error entry.
 */
export const setupSentryMonitoring = ({
  dsn,
  allowEvent,
  getUserCallback,
}: {
  dsn: string;
  allowEvent?: (event: any) => boolean;
  getUserCallback?: () => Promise<SentryRecordUser>;
}) => {
  if (!appEnv.isLocal()) {
    try {
      Sentry.init(
        getSentryParams({
          dsn,
          allowEvent,
          getUserCallback,
          env: appEnv.getEnv(),
        }),
      );
    } catch (e) {
      console.error('Sentry is misconfigured');
    }
  }
};

export const getSentryParams = ({
  dsn,
  allowEvent = () => true,
  getUserCallback,
  env,
}: {
  dsn: string;
  getUserCallback?: () => Promise<SentryRecordUser>;
  allowEvent?: (event: Sentry.ErrorEvent) => boolean;
  env: string;
}) => {
  return {
    dsn,
    // we want to capture all errors for now
    sampleRate: 1.0,
    // we don't need tracing at the moment
    enableTracing: false,
    environment: env,
    release: getCurrentAppVersion(),
    beforeSend: async (event: Sentry.ErrorEvent) => {
      try {
        if (!allowEvent(event)) {
          return null;
        }
      } catch (e) {
        const message =
          'Failed to verify if the event was allowed to be sent to Sentry.';
        // By default, we'll return the event if allowEvent fails.
        console.error(message, event);
        const newBreadcrumb: Sentry.Breadcrumb = {
          level: 'warning',
          message,
        };

        event.breadcrumbs?.push(newBreadcrumb);
      }

      try {
        // AWS Auth.config may not have been called yet at this point.
        // In this case, we'll catch and send the event anyways without the user info.
        if (getUserCallback) {
          const user = await getUserCallback();
          event.user = event.user || {};
          event.user.id = user.id;
          event.user.email = user.email;
        }

        return event;
      } catch (e) {
        return event;
      }
    },
  };
};

export interface SentryRecordUser {
  email: string;
  id: string;
}
